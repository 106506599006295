import { useContract, useClaimNFT, Web3Button } from "@thirdweb-dev/react";
import { useAddress } from "@thirdweb-dev/react";


const contractAddress = "0xd720932718EC8330Fc41A3ef328bf6209DD6cC5a";

function App() {
  const { contract } = useContract(contractAddress);
  const { mutate: claimNft, isLoading, error } = useClaimNFT(contract);
  const address = useAddress();
  return (
    <Web3Button
      contractAddress={contractAddress}
      action={() =>
        claimNft({
          to: address, // Use useAddress hook to get current wallet address
          quantity: 1,
        })
      }
    >
      Claim NFT
    </Web3Button>
  );
}
export default App;